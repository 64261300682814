<template>
  <div class="new-window text-lightblue">
    <screen
      :modeType="12"
      :screen="3"
      :winIndex="winIndex"
      :isPatrol="isPatrol"
      :isGroup="isGroup"
      :isCollect="isCollect"
      :user="user"
    />
  </div>
</template>

<script>
import Screen from '@/views/window/Screen';
import { clearStorage, getParamValue } from '@/tools';
window.addEventListener('beforeunload', () => {
  //清空轮询预案视频拉流
  clearStorage('patrolCamera');
});
export default {
  name: 'TwelveWindow',
  data() {
    return {
      isPatrol: false,
      winIndex: -1,
      user: '',
      isGroup: '0',
      isCollect:'0'
    };
  },
  components: {
    Screen,
  },
  created() {
    this.isPatrol = getParamValue('isPatrol');
    this.user = getParamValue('username');
    //组
    this.isGroup = getParamValue('isGroup');
    this.isCollect = getParamValue('isCollect');
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.new-window {
  width: 100%;
  height: 100%;
  /* background: rgba(31, 64, 103, 1); */
  background: rgb(0, 0, 0);
}
</style>
